import React, { useState } from "react";
import { Modal,Button } from "react-bootstrap";
import exclamation_icon from './assets/exclamation.svg'
import CrossSvg from "../src/projects/users/codercompanion/assets/svg/modalcross.svg"

const ErrorModalPopup = ({show,setShow,handleModalClose,message}) => {
  const handleClose=()=>{
    setShow(false);
  }
  return (
    <>
      <Modal
       show={show}
       onHide={handleModalClose}
       backdrop='static'
       keyboard={false}
       style={{textAlign:'center'}}
      >
        {message==="You do not have the required permission"&&<Modal.Header>
          <button onClick={handleClose} style={{background:'none',border:'none',marginLeft:'auto'}}><img src={CrossSvg} alt="close" style={{height:'12px'}}/></button>
        </Modal.Header>}
       <Modal.Body style={{padding:'20px 20px 20px 20px'}}>
        <img src={exclamation_icon} alt="exclaim" style={{height:'75px'}}/>
        {message!="You do not have the required permission"&&<h4 style={{marginBottom:'20px'}}>Session Time out!</h4>}
        {message}
        </Modal.Body>
       {message!="You do not have the required permission" && <Modal.Footer style={{borderTop:'none',padding:'20px'}}>
         <Button style={{margin:'0px',backgroundColor:'#032f49',border:'none'}} variant="primary" onClick={handleModalClose}>Login</Button>
       </Modal.Footer>}
      </Modal>
    </>
  );
};
export default ErrorModalPopup;
