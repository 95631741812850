import React, { useEffect, useState } from "react";
import "./RcmOverview.css";
import LineChart from "./RcmLineGraph";
import DonutChart from "./DonutChart";
import MultiSelectDropdown from "../../components/multiselect-dropdown/MultiSelectDropdown";
import hospitalrevenueidentified from "../../assets/png/hospital revenue identified.png";
import episodesProcessedimg from "../../assets/png/episodes processed.png";
import overnightEpisodesProcessedimg from "../../assets/png/overnight episodes processed.png";
import numberOfDrgChangesimg from "../../assets/png/potential drg charges.png";
import HighPriorityimg from "../../assets/png/high value episodes for review.png";
import MediumPriorityimg from "../../assets/png/medium value episodes for review.png";
import LowPriorityimg from "../../assets/png/low value episodes for review.png";
import { getRcmOverviewData } from "../../api/Service";
import { DownArrow, UpArrow } from "../../assets/svgimages/Images";
import { useLocation } from "react-router-dom";
import { elements } from "chart.js";
import { Spinner } from "../../../web-workflow/common/Helper";
import { Loader } from "../../../chat-ai/utils/util";

const initialFiltersObj = {
  priorityValue: [],
  principalDiagnosisCode: [],
  careType: [],
  coderName: [],
};
const getObject = (option) => ({
  label: option,
  value: option,
});
const doughnutData = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor:[],
      hoverBackgroundColor:[],
      borderWidth: 0,
    },
  ],
};
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const lineGraphInitialData = {
  labels: months,
  datasets: [
    {
      data: [],
      fill: false, 
      borderColor: "#3EBAD6", // Border color
      tension: 0.1,
      pointRadius: 0,
      pointHoverRadius: 4,
    },
  ],
};

const RcmOverview = ({ filterOptions, loadId }) => {
  const [filterToggle, setFilterToggle] = useState();
  const [filtersData, setFiltersData] = useState(initialFiltersObj);
  const [selectedFilters, setSelectedFilters] = useState(initialFiltersObj);
  const [revenueIdentified, setRevenueIdentified] = useState([]);
  const [episodesProcessed, setEpisodesProcessed] = useState([]);
  const [overnightEpisodesProcessed, setOvernighEpisodesProcessed] = useState(
    []
  );
  const [numberOfDrgChanges, setNumberOfDrgChanges] = useState([]);
  const [episodeReviewPriorities, setEpisodeReviewPriorities] = useState({});
  const [doughnutChartData, setDoughnutChartData] = useState(doughnutData);
  const [lineGraphData, setLineGraphData] = useState(lineGraphInitialData);
  const [colors,setColors]=useState([]);
  const [dashboardsLoad,setDashBoardLoad]=useState(false);
  
const generateLightColors = (numColors) => {
    if (numColors < 1) {
      throw new Error("The number of colors must be at least 1.");
    }
  
    const colors = [];
    const hueStep = 360 / numColors; // Distribute hues evenly across the color wheel
  
    for (let i = 0; i < numColors; i++) {
      const hue = i * hueStep; // Spread hues evenly
      const saturation = 60 + Math.random() * 20; // Saturation between 60% and 80%
      const lightness = 70 + Math.random() * 20; // Lightness between 70% and 90%
  
      // Convert HSL to RGB
      const rgb = hslToRgb(hue, saturation, lightness);
      const hexColor = rgbToHex(rgb);
      colors.push(hexColor);
    }
  
    return colors;
  };
  
  // Convert HSL to RGB
  const hslToRgb = (h, s, l) => {
    s /= 100;
    l /= 100;
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;
  
    let r, g, b;
    if (h >= 0 && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h >= 60 && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h >= 120 && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h >= 180 && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h >= 240 && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }
  
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);
  
    return { r, g, b };
  };
  
  // Convert RGB to HEX
  const rgbToHex = ({ r, g, b }) => {
    return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1).toUpperCase()}`;
  };
  

  useEffect(() => {
    const totalLabels = doughnutChartData.labels.length;
    if (totalLabels > 0) {
      const generatedColors = generateLightColors(totalLabels);
      setColors(generatedColors); // Set the colors state
      setDoughnutChartData((prevData) => ({
        ...prevData,
        datasets: [
          {
            ...prevData.datasets[0],
            backgroundColor: generatedColors,
            hoverBackgroundColor: generatedColors,
          },
        ],
      }));
    }
  }, [doughnutChartData.labels]);
  
//console.log(colors);
  const formatNumber = (num) => {
    return '$' + num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const percentageValue = 0.3;
  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };
  const handleSelectFilter = (name, selectedOptions) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [name]: selectedOptions.map((option) => option.value),
    }));
    getData({
      ...selectedFilters,
      [name]: selectedOptions.map((option) => option.value),
    });
  };
  useEffect(() => {
    if (Object.keys(filterOptions).length > 0) {
      // Extract options from filterOptions and update state
      const filtersObj = {
        priorityValue: filterOptions.priority_value?.map(getObject),
        principalDiagnosisCode:
          filterOptions.principal_diagnosis_code?.map(getObject),
        careType: filterOptions.care_type?.map(getObject),
        coderName: filterOptions.coder_name?.map(getObject),
      };
      setFiltersData(filtersObj);
    }
  }, [filterOptions]);
  const getData = (filters = selectedFilters) => {
    //console.log("SELECTED::::", filters);
    const body = {
      priority_value: filters.priorityValue,
      principal_diagnosis_code: filters.principalDiagnosisCode,
      care_type: filters.careType,
      coder_name: filters.coderName,
      load_id: loadId,
    };
    getRcmOverviewData(body,setDashBoardLoad).then((res) => {
      //console.log("powerbires::",res)
      const {
        query_for_count_of_priority,
        query_for_distinct_episode_failed,
        query_for_episodes_processed,
        query_for_overnight_episode,
        query_for_revenue_identified,
        query_for_pie_chart,
        query_for_line_graph,
      } = res;
     //console.log('query_for_line_graph',query_for_line_graph);
      const obj = {};
      query_for_count_of_priority.forEach((val) => {
        obj[val[0]] = val[1];
      });
      const totalCount = query_for_pie_chart.reduce((a, b) => a + b.count, 0);

      const labels = [];
      const data = [];

      query_for_pie_chart.forEach((chart) => {
        labels.push(chart.speciality ?? "Unknown");
        data.push(((chart.count / totalCount) * 100).toFixed());
      });
      const lineData = [];
      const difference = [];
      const auditDrugRevenue = [];
      query_for_line_graph.forEach((line) => {
        const { current_drg_revenue,audit_drg_revenue} = line;
        //console.log(line,predicted_drg_revenue,current_drg_revenue,'line')
        lineData.push(current_drg_revenue + Number(audit_drg_revenue));
        difference.push(current_drg_revenue - Number(audit_drg_revenue));
        auditDrugRevenue.push(Number(audit_drg_revenue));
      });
      //console.log('querfor',query_for_line_graph)
      setRevenueIdentified(query_for_revenue_identified);
      setEpisodesProcessed(query_for_episodes_processed);
      setOvernighEpisodesProcessed(query_for_overnight_episode);
      setNumberOfDrgChanges(query_for_distinct_episode_failed);
      setEpisodeReviewPriorities(obj);
      setDoughnutChartData({
        ...doughnutChartData,
        labels,
        datasets: [{ ...doughnutChartData.datasets[0], data }],
      });
      setLineGraphData({
        ...lineGraphData,
        datasets: [
          {
            ...lineGraphData.datasets[0],
            data: lineData,
            difference,
            auditDrugRevenue,
          },
        ],
      });
    }).catch(err=>console.log(err));
  };
  
  useEffect(() => {
    getData();
  }, [loadId]);

  let loc = useLocation()
  useEffect(()=>{

    if(loc.pathname === "/viewdashboard"){
      const element=document.querySelector("[href='/discovery-companion']");
      if(element){
        element.classList.add('active')
      }

  
    return ()=>{
      if(element){
      element.classList.remove('active')}

    }
  }
  },[])
  

  //console.log('overnightEpisodesProcessed',overnightEpisodesProcessed)
  return (
    <div className="rcm-overView-Wrapper">
      <div className="calulations-wrapper">
        <div className="top-calculation">
          <div className="hospital-rev d-flex">
            <div>
              <img
                src={hospitalrevenueidentified}
                alt="hospitalrevenueidentified"
              />{" "}
            </div>
            <div>
              <div className="label-color">Hospital Revenue Identified </div>{" "}
              {dashboardsLoad?<Loader/>:<div className="card-data-value-color"> 
                {revenueIdentified[0] &&`${formatNumber(revenueIdentified[0])}.00`}
              </div>}
              {/* <div className="arrow-percent-wrapper">
                <span>
                  {" "}
                  <UpArrow /> 40%{" "}
                </span>
                vs last month
              </div> */}
            </div>
          </div>
          <div className="episodes-processed d-flex">
            <div>
              <img src={episodesProcessedimg} alt="episodesProcessedimg" />
            </div>
            <div>
              <div className="label-color">Episodes Processed</div>
              {dashboardsLoad?<Loader/>:<div className="card-data-value-color">
                {episodesProcessed[0]}
              </div>}
              {/* <div className="arrow-percent-wrapper">
                <span className="downarrow">
                  {" "}
                  <DownArrow /> 40%{" "}
                </span>
                vs last month
              </div> */}
            </div>
          </div>
          <div className="episodes-processed-overnight d-flex">
            <div>
              <img
                src={overnightEpisodesProcessedimg}
                alt="overnightEpisodesProcessed"
              />
            </div>
            <div>
              <div className="label-color">Overnight Episodes Processed </div>
              {dashboardsLoad?<Loader/>:<div className="card-data-value-color">
                {overnightEpisodesProcessed.length && overnightEpisodesProcessed[0][0]}
              </div>}
              {/* <div className="arrow-percent-wrapper">
                <span>
                  {" "}
                  <UpArrow />
                  40%{" "}
                </span>
                vs last month
              </div> */}
            </div>
          </div>
          <div className="potential-drg-changes d-flex">
            <div>
              <img src={numberOfDrgChangesimg} alt="numberOfDrgChanges" />
            </div>
            <div>
              <div className="label-color">Potential DRG Changes </div>
              {dashboardsLoad?<Loader/>:<div className="card-data-value-color">
                {numberOfDrgChanges[0] && (numberOfDrgChanges[0] * percentageValue).toFixed()}
              </div>}
              {/* <div className="arrow-percent-wrapper">
                <span>
                  {" "}
                  <UpArrow />
                  40%{" "}
                </span>
                vs last month
              </div> */}
            </div>
          </div>
        </div>
        <div className="rcm-middle-cards">
          <div className="d-flex">
            <div>
              <img src={HighPriorityimg} alt="highvalue" />
            </div>
            <div>
              <p className="label-color">High Value Episodes for Review</p>
              {dashboardsLoad?<Loader/>:<p className="card-data-value-color">
                {episodeReviewPriorities[1]}
              </p>}
              {/* <p className="arrow-percent-wrapper">
                {" "}
                <span>
                  <UpArrow />
                  40%{" "}
                </span>
                vs last month
              </p> */}
            </div>
          </div>
          <div className="d-flex">
            <div>
              <img src={MediumPriorityimg} alt="highvalue" />
            </div>
            <div>
              <p className="label-color">Medium Value Episodes for Review</p>
              {dashboardsLoad?<Loader/>:<p className="card-data-value-color">
                {episodeReviewPriorities[2]}
              </p>}
              {/* <p className="arrow-percent-wrapper">
                {" "}
                <span className="downarrow">
                  <DownArrow />
                  40%{" "}
                </span>
                vs last month
              </p> */}
            </div>
          </div>
          <div className="d-flex">
            <div>
              <img src={LowPriorityimg} alt="highvalue" />
            </div>
            <div>
              <p className="label-color">Low Value Episodes for Review</p>
              {dashboardsLoad?<Loader/>:<p className="card-data-value-color">
                {episodeReviewPriorities[3]}
              </p>}
              {/* <p className="arrow-percent-wrapper">
                {" "}
                <span>
                <UpArrow/>
                  40%{" "}
                </span>
                vs last month
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="rcm-middle-charts">
        <div
          className="identified-episodes"
          style={{ border: "1px solid #EAEAEA", borderRadius: "6px" }}
        >
          <h5 className="text-start p-2" style={{ color: "#032F49" }}>
            Identified Episodes
          </h5>
          <LineChart data={lineGraphData} />{" "}
        </div>
        <div
          className="breakdown-by-speciality"
          style={{ border: "1px solid #EAEAEA", borderRadius: "6px" }}
        >
          <h5 className="text-start p-2" style={{ color: "#032F49" }}>
            Breakdown By Speciality
          </h5>
          <DonutChart data={doughnutChartData} colors={colors} />
        </div>
        <div className="graphs-wrapper">
          <div onClick={handleFilterToggle} className="filters-text">
            <span>filters</span>
          </div>
          {filterToggle && (
            <div className="filter-content p-2">
              <div className="filter-header d-flex gap-5">
                <h6>Filter Pane</h6>
                <span
                  className="back-arrow-filter"
                  onClick={handleFilterToggle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-return-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"
                    />
                  </svg>
                </span>
              </div>
              <div className="text-start mb-1">
                Audit Priority
                <MultiSelectDropdown
                  options={filtersData.priorityValue}
                  onSelect={(options) =>
                    handleSelectFilter("priorityValue", options)
                  }
                  
                  // buttonLabel={selectedFilters.priorityValue.length>0?selectedFilters.priorityValue.join(','):"Selected Options"}
                
                />
                {/* <input type="text" value={selectedFilters.priorityValue.join(',')}/> */}
              </div>
              <div className="text-start mb-1">
                Principal Diagnosis Code
                <MultiSelectDropdown
                  options={filtersData.principalDiagnosisCode}
                  onSelect={(options) =>
                    handleSelectFilter("principalDiagnosisCode", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Care Type
                <MultiSelectDropdown
                  options={filtersData.careType}
                  onSelect={(options) =>
                    handleSelectFilter("careType", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Coded By
                <MultiSelectDropdown
                  options={filtersData.coderName}
                  onSelect={(options) =>
                    handleSelectFilter("coderName", options)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RcmOverview;
