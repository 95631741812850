import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import feedbackFormConfig from "./FeedbackConfig.js";
import Rating from "react-rating-stars-component";
import "./Feedback.css";
import { getS3Url, fileUpload } from "./api/Service.js";
import { useAuth } from "../../../authContext/AuthContext.js";
import axiosInstance from "../../../axiosInterceptor.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const FeedbackForm = () => {
  const navigate = useNavigate(); // useNavigate is used for programmatic navigation
  // const permissions = JSON.parse(sessionStorage.getItem('permissions')); // Retrieve user permissions from storage

  // useEffect(() => {
  //   if (permissions.admin) {
  //     return; // Admins have access to all routes
  //   }
  //     // Check if the user has the required permission
  //     if (!permissions?.Feedback) {
  //         navigate('/no-access'); // Redirect to NoAccess page if permission is missing
  //     }
  // }, [permissions, navigate]);

  const initialFormData = {};
  feedbackFormConfig.forEach((section) => {
    section.fields.forEach((field) => {
      if (field.type === "rating") {
        initialFormData[field.name] = 0;
      } else if (field.type === "checkbox") {
        initialFormData[field.name] = false;
      } else if (field.type === "file") {
        initialFormData[field.name] = []; // Initialize as empty array for multiple files
      } else {
        initialFormData[field.name] = "";
      }
    });
  });
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  var hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours.toString().padStart(2, "0") : "12";
  const formatted = `${year}-${month}-${day},${hours}:${minutes}:${seconds} ${ampm}`;
  initialFormData["dateOfSubmission"] = formatted;

  const [formData, setFormData] = useState(initialFormData);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { userEmail } = useAuth();
  const fileInputRefs = useRef({});
  const totalfields = 18;

  const getUsernameFromEmail = (email) => {
    if (!email) return "";
    
    if (Array.isArray(email)) {
      // If email is an array, map through the array and get usernames for each
      return email.map((e) => e.split("@")[0]);
    }
    
    // If email is a string, process it normally
    return email.split("@")[0];
  };

  const handleChange = (e) => {
    const { name, type, checked, files, value } = e.target;
    let updatedValue;

    if (type === "checkbox") {
      updatedValue = checked;
    } else if (type === "file") {
      const fileArray = Array.from(files);
      const userName = getUsernameFromEmail(userEmail);
      updatedValue = fileArray.map((file) => {
        const timestamp = Date.now();
        const randomNum = Math.floor(1000000000 + Math.random() * 9000000000);
        const folderName = `${userName}-${timestamp}-${randomNum}`;
        const fileName = file.name;
        return { file, folderName, fileName };
      });
    } else {
      updatedValue = value;
    }

    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };

  const handleRatingChange = (value) => {
    setFormData({
      ...formData,
      userSatisfactionRating: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    //console.log('enter submit');
    if (currentStep === feedbackFormConfig.length - 1) {
      setIsSubmitting(true);
      try {
        const uploadedFiles = {};
        //console.log('enter try')
        // Upload files to S3 and get the URLs
        for (const key in formData) {
          if (
            Array.isArray(formData[key]) &&
            formData[key].every((item) => item.file)
          ) {
            //console.log('entered if',formData[key])
            uploadedFiles[key] = [];
            for (const { file, folderName, fileName } of formData[key]) {
              // Get S3 signed URL
              const { url, fields } = await getS3Url(folderName, fileName);
              // Prepare form data for the file upload
              const formDataForUpload = new FormData();
              //console.log(formDataForUpload,'formDataForUpload');
              Object.keys(fields).forEach((fieldKey) => {
                formDataForUpload.append(fieldKey, fields[fieldKey]);
              });
              formDataForUpload.append("file", file);

              // Upload the file to S3
              await fileUpload(url, formDataForUpload);

              // Store the S3 URL for form submission
              uploadedFiles[key].push(fields.key);
            }
            //console.log('checkform data',formData)
          }
        }

        const finalPayload = {
          ...formData,
          ...uploadedFiles, // Add uploaded file URLs to the payload,
          dateOfSubmission: new Date(
            formData.dateOfSubmission
          ).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          }),
        };
        //console.log('final',finalPayload);
        if (
          finalPayload.email === "" ||
          finalPayload.userSatisfactionRating === 0
        ) {
          setIsSubmitting(false);
          toast.error("Please submit mandatory feedback ( Rating, Email)", {
            autoClose: 15000,
          });
        } else {
          const response = await axiosInstance.post(
            "https://4538tohaaf.execute-api.ap-southeast-2.amazonaws.com/feedback_form_ics_v1/",
            finalPayload,
            {
              headers: {
                "Content-Type": "application/json", // Set content type to JSON
              },
            }
          );

          setResponseMessage("Feedback submitted successfully!");
          setFormData(initialFormData);
          // Clear file inputs
          for (const key in fileInputRefs.current) {
            if (fileInputRefs.current[key]) {
              fileInputRefs.current[key].value = "";
            }
          }
          setTimeout(() => {
            setResponseMessage("");
          }, 3000);
        }
      } catch (error) {
        setResponseMessage("Error submitting feedback. Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const renderField = (field) => {
    switch (field.type) {
      case "text":
      case "email":
        return (
          <input
            type={field.type}
            className="form-control"
            id={field.name}
            name={field.name}
            placeholder={field.placeholder}
            value={formData[field.name]}
            onChange={handleChange}
            disabled={field.disabled || false}
            required={!field.optional}
          />
        );
      case "textarea":
        return (
          <textarea
            className="form-control"
            id={field.name}
            name={field.name}
            rows="5"
            placeholder={field.placeholder}
            value={formData[field.name]}
            onChange={handleChange}
            required={field.optional}
          ></textarea>
        );
      case "select":
        return (
          <select
            className="form-select"
            id={field.name}
            name={field.name}
            value={formData[field.name]}
            onChange={handleChange}
            required={field.optional}
          >
            {field.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case "rating":
        return (
          <Rating
            count={5}
            value={formData[field.name]}
            onChange={handleRatingChange}
            size={30}
            activeColor="#ff9900"
          />
        );
      case "file":
        return (
          <input
            type="file"
            className="form-control"
            id={field.name}
            name={field.name}
            onChange={handleChange}
            multiple
            ref={(el) => (fileInputRefs.current[field.name] = el)}
          />
        );
      case "checkbox":
        return (
          <div
            className={`form-checks ${
              formData[field.name] ? "checked-background" : ""
            }`}
          >
            <label className="form-checks-label" htmlFor={field.name}>
              {field.label}
            </label>
            <input
              type="checkbox"
              className="form-checks-input"
              id={field.name}
              name={field.name}
              checked={formData[field.name]}
              onChange={handleChange}
            />
          </div>
        );
      default:
        return null;
    }
  };
  const goToNextStep = () => {
    if (currentStep < feedbackFormConfig.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const filledFields = Object.values(formData).filter((value) => value).length;
  const progress = (filledFields / totalfields) * 100;
  return (
    <div className="container mt-5">
      <h1 className="feedback-section-header text-center">Feedback Form</h1>
      <div>
        <h2 className="feedback-section-headers text-center">
          {feedbackFormConfig[currentStep].section}
        </h2>
        <div className="d-flex justify-content-center align-items-center gap-1 my-5">
          <p className="feedback-progress-text">YOUR PROGRESS</p>
          <div className="feedback-progress-parent">
            {progress <= 100 ? (
              <div
                className="feedback-progress-child"
                style={{ width: `${progress}%` }}
              ></div>
            ) : (
              <div
                className="feedback-progress-child"
                style={{ width: "100%" }}
              ></div>
            )}
          </div>
          {progress <= 100 ? (
            <p className="feedback-progress-percent">{Math.floor(progress)}%</p>
          ) : (
            <p className="feedback-progress-percent">{100}%</p>
          )}
        </div>
        {feedbackFormConfig[currentStep].fields.map((field, fieldIndex) => (
          <div className="mb-3" key={fieldIndex}>
            <form>
              <label htmlFor={field.name} className="form-label">
                {field.type !== "checkbox" ? field.label : ""}
              </label>
              {renderField(field)}
            </form>
          </div>
        ))}

        <div className="form-button-group">
          {currentStep > 0 && (
            <button
              type="button"
              className="form-back-btn"
              onClick={goToPreviousStep}
            >
              Back
            </button>
          )}

          {currentStep < feedbackFormConfig.length - 1 ? (
            <button
              type="button"
              className="form-next-btn"
              onClick={goToNextStep}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="form-submit-btn"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          )}
        </div>
      </div>
      {responseMessage && <p className="mt-3">{responseMessage}</p>}
    </div>
  );
};

export default FeedbackForm;
