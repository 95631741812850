import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuditRuleDetailsHeader from "./components/header/Header";
import AuditRuleDetails from "./components/auditruledetails/AuditRuleDetails";
import { ToastContainer } from 'react-toastify';

const PowerApp = () => {
  const navigate = useNavigate(); // useNavigate is used for programmatic navigation
  const permissions = JSON.parse(sessionStorage.getItem('permissions'));
  useEffect(() => {
    // Retrieve user permissions from storage
    if (permissions.admin) {
      return; // Admins have access to all routes
    }
    if (
      // permissions?.super_user || // Super users
      // permissions?.super_user_readonly || // Read-only super users
      // !permissions?.AuditRuleSettings // Normal users without AuditRuleSettings permission
      (permissions?.super_user || permissions?.super_user_readonly) &&
    (!permissions?.AuditRuleSettings || permissions?.AuditRuleSettings.length === 0)
      
    ) {
      navigate('/no-access'); // Redirect to NoAccess page
    }
  }, [permissions,navigate]);
  return (
    <>
      {/* <AuditRuleDetailsHeader /> */}
      <ToastContainer position="top-center"/>
      <AuditRuleDetails />
    </>
  );
};

export default PowerApp; 