import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./MultiSelectDropdown.css";

const MultiSelectDropdown = ({ options, onSelect }) => {
  //console.log('newOptions',options);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const buttonLabel=selectedOptions&& selectedOptions.length>0?selectedOptions.map((option)=>option.label).join(', '):"Select options"
  const handleSelect = (eventKey,event) => {
    event.stopPropagation();
    const option = options.find((opt) => opt.value === eventKey);
    const isSelected = selectedOptions.includes(option);

    if (isSelected) {
      const options = selectedOptions.filter((opt) => opt !== option);
      setSelectedOptions(options); 
      onSelect(options);
    } else {
      const options = [...selectedOptions, option];
      setSelectedOptions(options); 
      onSelect(options);
    }
  };

  const handleSelectAll = (e) => {
    e.stopPropagation();
    if (allSelected) {
      onSelect([]); 
      setSelectedOptions([]);
    } else {
      setSelectedOptions(options);
      onSelect(options); 
    }
    setAllSelected(!allSelected);
  };
  useEffect(() => {
    if(selectedOptions && options){
      if (selectedOptions.length === options.length) {
        setAllSelected(true);
      } else {
        setAllSelected(false);
      }
    }
  }, [selectedOptions]);
  //console.log('checkselect',selectedOptions)
  return (
    <DropdownButton
      id="dropdown-basic-button"
      title={buttonLabel}
      variant="secondary"
      className="multi-select-dropdown"
    >
      <Dropdown.Item as="button" onClick={handleSelectAll}>
        <Form.Check
          type="checkbox"
          label="Select All"
          checked={allSelected}
          onChange={handleSelectAll}
          onClick={(event) => handleSelectAll(event)}
        />
      </Dropdown.Item>
      <Dropdown.Divider />
      {options?.map((option) => (
        <OverlayTrigger
        key={option.value}
        placement="left"
        overlay={<Tooltip id={`tooltip-${option.value}`}>{option.label}</Tooltip>}
      >
        <Dropdown.Item
          as="button"
          key={option.value}
          eventKey={option.value}
          onClick={(event) => handleSelect(option.value, event)}
          // onClick={() => handleSelect(option.value)}
          className="dropdown-item"
        >
          <Form.Check
            type="checkbox"
            label={option.label}
            checked={selectedOptions.includes(option)}
            onChange={(event) => handleSelect(option.value,event)}
            className="form-check-label"
          />
        </Dropdown.Item>
        </OverlayTrigger>
      ))}
    </DropdownButton>
  );
};

export default MultiSelectDropdown;
