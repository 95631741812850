export const initilaDetailsObj = {
    "name": "",
    "file_name": "",
    "admission_date": null,
    "separation_date": null,
    "status": "",
    "priority_value": null,
    "insurer_identifier": "",
    "audit_rule_short_description": "",
    "audit_rule_long_description": "",
    "audit_rule_category": "",
    "care_type": "",
    "audit_rule_ref_no": "",
    "principal_diagnosis_code": "",
    "episode_failed_multiple_rules": false,
    "additional_diagnoses": "",
    "drg_code": "",
    "procedure_codes1": "",
    "current_drg_revenue": null,
    "sameday_status": "",
    "predicted_drg_revenue": 0,
    "principal_mbs_item_number": "",
    "hospital_type": "",
    "prosthesis_charge": null,
    "ar_drg_version": ""
}

// {
//     "name": "sagar-Pallas AI-1714495706683",
//     "file_name": "public/sagar-Pallas AI-1714495706683/2023_Data_Set.hcp",
//     "admission_date": 3032023,
//     "seperation_date": 4032023,
//     "status": "Audited",
//     "priority_value": 3,
//     "insurer_identifier": "MPL",
//     "audit_rule_short_description": "Missing prosthesis charge (IUD insertion)",
//     "audit_rule_long_description": "Episodes identified where no prosthetic charge has been raised in an episode where an insertion of IUD has been performed.",
//     "audit_rule_category": "Look for Missing Prosthesis Charge",
//     "care_type": "Acute care",
//     "audit_rule_ref_no": "MP013",
//     "principal_diagnosis_code": "2N832",
//     "episode_failed_multiple_rules": false,
//     "additional_diagnoses": "2K668",
//     "drg_code": "N07A",
//     "procedure_codes1": "3550600",
//     "current_drg_revenue": 4094,
//     "sameday_status": "Overnight patient",
//     "audit_drg_revenue": 0,
//     "principal_mbs_item_number": "35630",
//     "hospital_type": "Private",
//     "prosthesis_charge": 0,
//     "ar_drg_version": "nan"
// }